// 浏览器信息
export const browser = {
  versions: (function () {
    const u = navigator.userAgent;
    return {
      // 移动终端浏览器版本信息
      trident: u.indexOf("Trident") > -1, // IE内核
      presto: u.indexOf("Presto") > -1, // opera内核
      webKit: u.indexOf("AppleWebKit") > -1, // 苹果、谷歌内核
      gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, // 火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
      android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, // android终端或uc浏览器
      iPhone: u.indexOf("iPhone") > -1, // 是否为iPhone或者QQHD浏览器
      iPad: u.indexOf("iPad") > -1, // 是否iPad
      webApp: u.indexOf("Safari") == -1 // 是否web应该程序，没有头部与底部
    };
  })(),
  language: (navigator.browserLanguage || navigator.language).toLowerCase()
};

// 时间戳日期格式化
export function dateFormat(timestamp, format = "YYYY-MM-DD hh:mm:ss") {
  if (!timestamp) {
    return;
  } else {
    const addzero = (n) => {
      const nb = parseInt(n);
      if (typeof nb === "number" && !isNaN(nb)) {
        return n < 10 ? `0${nb}` : n;
      } else {
        return "00";
      }
    };
    const date = new Date(timestamp);
    const Y = date.getFullYear();
    const M = date.getMonth() + 1;
    const D = date.getDate();

    const h = date.getHours();
    const m = date.getMinutes();
    const s = date.getSeconds();
    return format
      .replace("YYYY", Y)
      .replace("MM", addzero(M))
      .replace("DD", addzero(D))
      .replace("hh", addzero(h))
      .replace("mm", addzero(m))
      .replace("ss", addzero(s));
  }
}
// 字符串脱敏
export const stringHypose = (value, [s, e] = [3, 7]) => {
  value = value ? String(value) : null;
  let asterisk = "";
  let _num = e - s;
  for (let i = 0; i < _num; i++) {
    asterisk += "*";
  }
  return value
    ? `${value.slice(0, s)}${asterisk}${value.slice(e, value.length)}`
    : "-";
};

/**
 * 千分位数字格式化
 * @param {Number|String} value
 * @param {int} fixed 修正位数
 * @returns {null|String}
 */
export const amount = (value, fixed = 2) => {
  if (isNaN(value)) return null;
  if ([null, undefined, ""].includes(value)) return null;
  if (value === 0) return parseFloat(0).toFixed(fixed);
  let num = value.toString().replace(/,/g, "");
  num = parseFloat(num || 0);
  num = num.toFixed(+fixed || 0).toString();
  num = num.split(".");
  if (num.length === 1) return num[0].replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
  return [num[0].replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"), num[1]].join(".");
};

/**
 * 单元格金额处理
 * @param {Number|String} value
 * @param {int} placeholder 值为空时的占位符
 * @param {int} fixed 修正位数
 * @returns String
 */
export const columnAmount = (
  value,
  { placeholder = "-", fixed = 2, prefix = "", suffix = "" } = {}
) => {
  if (value === 0) return 0;
  if (isNaN(value)) return placeholder;
  const _amount = amount(value, fixed);
  return _amount === null ? placeholder : prefix + _amount + suffix;
};

// 深拷贝
export const deepClone = (obj) => {
  var objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        if (obj[key] && typeof obj[key] === "object") {
          objClone[key] = deepClone(obj[key]);
        } else {
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
};

// 复制内容到剪切板
export function copyToClipboard(text) {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
}
