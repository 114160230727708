import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";

Vue.use(Vuex);
const state = {
  userInfo: {},
  productInfo: {},
  userOrderInfo: {}
  // merchantNo: ""
};
const getters = {
  getProductInfo(state) {
    return state.productInfo;
  },
  getUserInfo(state) {
    return state.userInfo;
  },
  getUserOrderInfo(state) {
    return state.userOrderInfo;
  }
  // getMerchantNo(state) {
  //   return state.merchantNo;
  // }
};
const mutations = {
  SET_USERINFO: (state, data) => {
    const d = data || {};
    state.userInfo = d;
  },
  SET_PRODUCT_INFO: (state, data) => {
    state.productInfo = data || {};
  },
  SET_USER_ORDER_INFO: (state, data) => {
    state.userOrderInfo = data || {};
  },
  // SET_MERCHANT_NO: (state, data) => {
  //   state.merchantNo = data;
  // },
  CLEAR: (state) => {
    state.userInfo = {};
    state.productInfo = {};
    state.userOrderInfo = {};
    // state.merchantNo = "";
  }
};
const actions = {
  setUserInfo: ({ commit }, data) => {
    commit("SET_USERINFO", data);
  },
  setProductInfo: ({ commit }, data) => {
    commit("SET_PRODUCT_INFO", data);
  },
  setUserOrderInfo: ({ commit }, data) => {
    commit("SET_USER_ORDER_INFO", data);
  },
  // setMerchantNo: ({ commit }, data) => {
  //   commit("SET_MERCHANT_NO", data);
  // },
  init({ commit }) {
    commit("CLEAR");
  }
};
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {},
  plugins: [persistedState()]
});
