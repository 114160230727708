export const _CATCH_PREFIX = "_sfxh";
import qs from "qs";
export const _APP_TOKEN_ = `${_CATCH_PREFIX}_catch_user_token`;

export const setToken = (token) => {
  try {
    localStorage.setItem(_APP_TOKEN_, token);
  } catch (error) {
    console.error(error);
  }
};

export const getToken = () => {
  try {
    return localStorage.getItem(_APP_TOKEN_);
  } catch (error) {
    console.error(error);
  }
};

export const removeToken = () => {
  try {
    localStorage.removeItem(_APP_TOKEN_);
  } catch (error) {
    console.error(error);
  }
};

export const clearCache = () => {
  try {
    removeToken();

    // 需要删除的缓存前缀
    const _regx = new RegExp(`(${_CATCH_PREFIX})`);

    // 遍历删除缓存
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (_regx.test(key)) {
        localStorage.removeItem(key);
      }
    }

    return true;
  } catch (error) {
    console.log(error);
  }
};

export const tologin = () => {
  clearCache();
  // 转到登录页
  setTimeout(() => {
    let isLoginPage = window.location.href.includes("/login");
    if (!isLoginPage) {
      const sessionData = sessionStorage.getItem("_app_cache_url_params");
      let params = "";
      if (sessionData) {
        const productInfo = JSON.parse(sessionData);
        const { productNo, invitationCode, merchantNo } = productInfo;
        let query = {
          productNo,
          invitationCode,
          merchantNo,
          source: encodeURIComponent("/apply-list")
        };

        params = qs.stringify(query);
      }
      // const url = `/login?return=${encodeURIComponent(window.location.href)}${
      //   params ? "&" + params : ""
      // }`;
      const url = `/login${params ? "?" + params : ""}`;
      window.location.href = url;
    }
  }, 1500);
};
window.tologin = tologin;
