// 留意使用场景的this指向

const prefix = "_app_form_cache_";
export const setFormCache = function (data, suff) {
  const name = this.$route.name;
  sessionStorage.setItem(`${prefix}${name}_${suff}`, JSON.stringify(data));
};
export const getFormCache = function (suff = "") {
  const name = this.$route.name;
  const item = sessionStorage.getItem(`${prefix}${name}_${suff}`) || `{}`;
  return JSON.parse(item);
};
export const removeFormCache = function (suff = "") {
  const name = this.$route.name;
  sessionStorage.removeItem(`${prefix}${name}_${suff}`);
};
export const clearAllFormCache = function () {
  const _regx = new RegExp(`(${prefix})`);
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    if (_regx.test(key)) {
      sessionStorage.removeItem(key);
    }
  }
};
