import Vue from "vue";
import VueRouter from "vue-router";

import { clearCache } from "@/utils/app";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/apply"
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录"
    },
    component: () => import("@/views/login/Index.vue")
  },
  {
    path: "/loginv2",
    name: "Loginv2",
    meta: {
      title: "登录"
    },
    component: () => import("@/views/login/Login.vue")
  },
  {
    path: "/apply",
    name: "Apply",
    meta: {
      title: "申请页"
    },
    component: () => import("@/views/apply/Index.vue")
  },
  {
    path: "/apply-detail",
    name: "ApplyDetail",
    meta: {
      title: "申请信息"
    },
    component: () => import("@/views/apply/detail/Detail.vue")
  },
  {
    path: "/apply-list",
    name: "ApplyList",
    meta: {
      title: "申请列表"
    },
    component: () => import("@/views/apply/List.vue")
  },
  {
    path: "/personal-info",
    name: "PersonalInfo",
    meta: {
      title: "个人信息"
    },
    component: () => import("@/views/apply/detail/PersonalInfo.vue")
  },
  {
    path: "/works-info",
    name: "WorksInfo",
    meta: {
      title: "工作信息"
    },
    component: () => import("@/views/apply/detail/WorksInfo.vue")
  },
  {
    path: "/vehicle-info",
    name: "VehicleInfo",
    meta: {
      title: "车辆信息"
    },
    component: () => import("@/views/apply/detail/VehicleInfo.vue")
  },
  {
    path: "/materials-info",
    name: "MaterialsInfo",
    meta: {
      title: "进件材料"
    },
    component: () => import("@/views/apply/detail/MaterialsInfo.vue")
  },
  {
    path: "/materials-info-upload",
    name: "MaterialsInfoUpload",
    meta: {
      title: "进件材料"
    },
    component: () => import("@/views/apply/detail/MaterialsInfoUpload.vue")
  },
  {
    path: "/bindcard-1",
    name: "BindCard1",
    meta: {
      title: "绑卡"
    },
    component: () => import("@/views/apply/detail/BindCard1.vue")
  },
  {
    path: "/bindcard-2",
    name: "BindCard2",
    meta: {
      title: "绑卡"
    },
    component: () => import("@/views/apply/detail/BindCard2.vue")
  },
  {
    // 申请人担保人
    path: "/guarantor",
    name: "Guarantor",
    meta: {
      title: "担保人"
    },
    component: () => import("@/views/apply/guarantor/Index.vue")
  },
  {
    // 短链担保人
    path: "/guarantee",
    name: "Guarantee",
    meta: {
      title: "担保"
    },
    component: () => import("@/views/apply/guarantor/Guarantee.vue")
  },
  {
    // 短链担保人个人信息
    path: "/guarantee-person",
    name: "GuaranteePerson",
    meta: {
      title: "担保"
    },
    component: () => import("@/views/apply/guarantor/PersonInfo.vue")
  },
  {
    path: "/identity-portrait",
    name: "IdentityPortraitFace",
    meta: {
      title: "活体认证" // 活体认证
    },
    component: () => import("@/views/face/Index.vue")
  },
  {
    path: "/identity-portrait-face",
    name: "IdentityPortraitFace",
    meta: {
      title: "活体认证" // 活体认证
    },
    component: () => import("@/views/face/IdentityPortrait.vue")
  },
  {
    path: "/authorization",
    name: "Authorization",
    meta: {
      title: "授权书"
    },
    component: () => import("@/views/authorization/Index.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

const whiteList = [
  "/login",
  "/loginv2",
  "/identity",
  "/identity-ocr",
  "/identity-portrait",
  "/guarantee",
  "/guarantee-person"
];

// import store from "@/store";
import { getToken } from "@/utils/app";
import { Toast } from "vant";

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "H5";
  }
  if (whiteList.includes(to.path)) {
    next();
  } else {
    const token = getToken();
    const isLogin = !!token;
    if (!isLogin) {
      clearCache();
      let query = to.query;
      if (to.name == "ApplyList") {
        query.source = encodeURIComponent("/apply-list");
      }
      Toast.fail({
        duration: 1500,
        message: "登录信息已过期，请重新登录",
        onClose: () => {
          next({ path: "/login", query });
        }
      });
    } else next();
  }
});

export default router;
