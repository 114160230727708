<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import { tologin } from "./utils/app";

export default {
  created() {
    window.test401Event = () => {
      const productInfo = this.$store.state.productInfo;
      if (productInfo && JSON.parse(productInfo) != "{}") {
        const { productNo, invitationCode, merchantNo } = productInfo;
        tologin({ productNo, invitationCode, merchantNo });
      }
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    clear() {
      window.sessionStorage.clear();
      window.localStorage.clear();
    }
  }
};
</script>
<style lang="scss">
@import "./styles/app.scss";
</style>
